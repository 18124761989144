
.bar {
  display: flex;
  flex-direction: row;
  // background-color: #107c41;
  justify-content: space-between;
  align-items: center;
  
  
  .left {
    display: flex;
    flex-direction: row;
    flex-grow: 1; /* default 0 */
    
    .logo {
      display: inline-block;
      // height: 100%;
      vertical-align: middle;
      background-color: white;
      border-bottom: 1px solid #107c41;

      .center-image {
        // transform: translateY(10%);
        margin:8px;
      }
    }
    
    .apptitle {
      // align-items: center;
      // vertical-align:middle;
      // margin: 0.5em;
      // color: white;
      // font-size:larger;
      
      /* String-product-name */
      
      position: static;
      margin-top:13px;
      margin-bottom:13px;
      margin-left:10px;
      left: 8px;
      top: calc(50% - 22px/2);
      
      /* SegoeUI Semibold / 16 - ms-fontSize-16 FontSizes.size16 */
      font-family: Segoe UI;
      font-size: 16px;
      line-height: 22px;
      /* identical to box height, or 137% */
      
      /* Neutrals / White  #FFFFFF */
      color: #FFFFFF;
      
      /* Inside auto layout */
      flex: none;
      order: 0;
      flex-grow: 0;      
      
    }
  }
  
  .right {
    display: flex;
    flex-direction: row;
    justify-content:end;
    flex-grow: 1; /* default 0 */
    
    .notification {
      margin-left: 5px;
      margin-right: 5px;
    }
    
    .user {
      margin-left: 5px;
      margin-right: 2px;
    }
    
  }
  
  .search {
    flex-grow: 1; /* default 0 */
    vertical-align: middle;

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
      padding-right:0px;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
      padding-right:0px;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
      padding-right:50px;
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
      padding-right:100px;
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
      padding-right:200px;
    }


  }
}

