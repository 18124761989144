::-webkit-scrollbar {
    width: 12px !important;
}


/* Track */
::-webkit-scrollbar-track {
    background: rgb(71,85,105) !important;
    border:none !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(15,23,42) !important;
    border:none !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(41,55,85) !important;
}