.header-text {
    font-size: 20px;
    color: white;

}

.arrow-icon {
    font-size: 20px;
    color: white;
}

@media screen and (max-width: 1800px) {
    .header-text {
        font-size: 18px;
    }

    .arrow-icon {
        font-size: 16px;

    }
}

@media screen and (max-width: 1300px) {
    .header-text {
        font-size: 14px;
    }
    .arrow-icon {
        font-size: 12px;

    }
}