::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
    border: none !important;
}


/* color of the tracking area */
::-webkit-scrollbar-track {
    background: #ebebeb !important;
    border-radius: 2px !important;
    border: none !important;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(70, 96, 129) !important;
    border-radius: 20px !important;
    border: 2px solid #ebebeb !important;
}

.popup-container {
    display: block !important;
    border:1px solid white;
    position: relative;
    max-width: 90vw;
    padding:10px;
    max-height: 90vh;
    min-width: 60vw;
    overflow: auto;
    margin:auto;
    top:5vh;
}