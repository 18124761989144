::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
    border: none !important;
}


/* color of the tracking area */
::-webkit-scrollbar-track {
    background: #ebebeb !important;
    border-radius: 2px !important;
    border: none !important;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(70, 96, 129) !important;
    border-radius: 20px !important;
    border: 2px solid #ebebeb !important;
}

.page-section {
    width: 50%;
}

@media screen and (max-width: 1300px) {
    .page-section {
        width: 100%;
    }
}