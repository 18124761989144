$intesa-color:rgb(37,177,0);
.main-dashboard-title::first-letter{
    color:$intesa-color;
    font-weight: bold;
    // font-style: italic;
    // margin-right: 2px;
}

.main-dashboard-title {
    
    font-size: 2rem;
    font-weight: bold;
    color: white;
    // font-family: Georgia, 'Times New Roman', Times, serif;
}

.beta-text{
    font-size: 1rem;
}

@media screen and (max-width: 1300px) {
    .beta-text {
      font-size: 0.8rem;
    }
   }