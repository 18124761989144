.container-title {
  font-size: 28px;
}

@media screen and (max-width: 1600px) {
 .container-title {
   font-size: 24px;
 }
}

@media screen and (max-width: 1300px) {
 .container-title {
   font-size: 20px;
 }
}

@media screen and (max-width: 1000px) {
 .container-title {
   font-size: 16px;
 }
}

.alert-container {
  display: flex;
  flex-direction: row;
}
.alert-date {
  font-size: 16px;
  text-align: center;
  width: 100%;
}

.alert-row-icon {
  font-size: 30px;
}

/* .alert-row-icon:hover {
  font-size: 32px;
} */

.metric-name-section {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 50%;
}

.notifications-section {
  display: flex;
  flex-direction: row;
  height: 100%;
  /* width: 50%; */
}

.actions-section {
  height: 100%;
  width: 10%;
  border-right: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
}

.target-container-section {
  width: 40%;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dates-section {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  height: 100%;
  width: 40%;
  text-align: left;
}

.target-section {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  height: 100%;
  width: 60%;
  border-left: 1px solid lightgray;
  padding-left: 8px;
}

@media screen and (max-width: 1800px) {
  .alert-date {
    font-size: 14px;
  }

  .alert-row-icon {
    font-size: 24px;
  }

  .alert-row-icon:hover {
    font-size: 26px;
  }
}

@media screen and (max-width: 1600px) {
  .alert-container {
    flex-direction: column;
  }
  .alert-date {
    font-size: 12px;
    text-align: center;
  }

  .alert-row-icon {
    font-size: 20px;
  }

  .alert-row-icon:hover {
    font-size: 22px;
  }

  .metric-name-section {
    width: 100%;
    height: unset;
    flex-direction: column;
  }

  .actions-section {
    width: 100%;
    border: 1px solid lightgrey;
  }

  .target-container-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .dates-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-top: 4px;
    padding: 8px;
    text-align: center;
  }

  .target-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border-left: unset;
    padding-left: unset;
    padding-top: 8px;
    border-top: 1px solid lightgray;
  }
}
