::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
}


/* color of the tracking area */
::-webkit-scrollbar-track {
    background: #ebebeb !important;
}

::-webkit-scrollbar-thumb {
    background-color: #8b8e94 !important;
    border-radius: 12px !important;
    border: 1px solid #ebebeb !important;
}