.file-p{
    font-size: 15px;
}
@media screen and (max-width:2100px){
    .file-p{
        font-size: 15px;
    }
}
@media screen and (max-width:1500px){
    .file-p{
        font-size: 13px;
    }
}
@media screen and (max-width:1000px){
    .file-p{
        font-size: 12px;
    }
}