::-webkit-scrollbar:vertical {
    width: 10px !important;
    height: 10px !important;
    /* display: block !important; */
  }
 
 
  /* color of the tracking area */
  ::-webkit-scrollbar-track {
    background: rgb(70, 96, 129) !important;
  }
 
  ::-webkit-scrollbar-thumb {
    background-color: #222A35 !important;
    border-radius: 20px !important;
    border: 1px solid grey !important;
  }