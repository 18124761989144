.nav-text{
    font-size: 18px;
}
.nav-icon{
    font-size: 12px;
}
@media screen and (max-width: 1800px) {
    .nav-text {
        font-size: 16px;
    }
    .nav-icon{
        font-size: 10px;
    }
}

@media screen and (max-width: 1300px) {
    .nav-text {
        font-size: 14px;
    }
    .nav-icon{
        font-size: 9px;
    }
}