#segment-value{
    color:'white';
    font-size: 1.5rem;
}

#currennt-value{
    margin-top: 10px;
}

.title{
    font-size: 17px;
}
@media screen and (max-width:2100px){
    .title{
        font-size: 16px;
    }
}
@media screen and (max-width:1070px){
    .title{
        font-size: 14px;
    }
}