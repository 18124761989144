.outline-none{
    outline: none !important;
    border:none !important;
}
.outline-none:focus{
    outline: none !important;
    border:none !important;
}
.outline-none> div:hover{
    outline: none !important;
    border:none !important;
}

input[type="file"]{
    display:none;
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 5px 12px;
    cursor: pointer;
    margin:6px 2px 6px 4px;
    border-radius: 6px;
    color:white;
}