.outlined-input{
    height: 60px;
    background-color: white; 
    border: 1px solid black; 
    color:black;
    border-radius: 0px !important;
}
.outlined-input:disabled{
    border-color: lightgray !important;
}
.outlined-input-label{
    color: black;
}
.outlined-input-label:disabled{
    color:lightgray;
}