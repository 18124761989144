.ms-ComboBox-CaretDown-button{
    color:white !important;
}
.ms-ComboBox-container:hover{
    color:white !important;
}
.ms-ComboBox-Input:hover{
    color:white !important;
}
.ms-Button:hover{
    background-color: transparent;
    color: white !important;
}
.ms-ComboBox-option{
    color:white !important;
}
.ms-ComboBox-option:focus{
    color:white !important;
    background-color: red !important;
}