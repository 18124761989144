.rdrInRange{
    background-color: #107c41;
}
.rdrStartEdge{
    background-color: #107c41;
}
.rdrEndEdge{
    background-color: #107c41;
}
.rdrStaticRangeSelected > span{
    color:#107c41
}
.rdrDateDisplayItemActive{
    border-color:#107c41;
}