#pile-icon:hover {
    color: white !important;
}

::-webkit-scrollbar {
    width: 15px !important;
    height: 15px !important;
}


/* color of the tracking area */
::-webkit-scrollbar-track {
    background: #ebebeb !important;
}

::-webkit-scrollbar-thumb {
    background-color: #8b8e94 !important;
    border-radius: 20px !important;
    border: 3px solid #ebebeb !important;
}

.highlight{
    display: inline-block;
    padding: .25em 0;
    background: rgba(255,165,0,0.25);
    color: #ffffff;
}