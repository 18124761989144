.header-text {
    font-size: 14px;
    color:white
}
.row-text {
    font-size: 14px;
    color:black
}

.row-text-uom {
    font-size: 13px;
    color:black
}
@media screen and (max-width: 2000px) {
    .header-text {
        font-size: 13px;
    }
    .row-text {
        font-size: 13px;
    }
    .row-text-uom {
        font-size: 12px;
    }
}
