.right-toolbar{
    display: flex;
    flex-direction: row;
    padding: 2px;
    align-self: flex-end;
    align-items: flex-end;
    margin-left: auto;
}

.left-toolbar{
    display: flex;
    flex-direction: row;
    padding: 2px;
    align-self: flex-start;
    align-items: start;
}