.topbar-title{
    font-size: 24px;
}

@media screen and (max-width: 1600px) {
    .topbar-title{
        font-size: 20px;
    }
    
}

@media screen and (max-width: 1300px) {
    .topbar-title{
        font-size: 16px;
    }
    
}